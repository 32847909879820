import { Component } from "@angular/core";

@Component({
	selector: "app-settings",
	templateUrl: "./settings.component.html",
	styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent {
	myFiles: any[] = [];
	myFiless: any[] = [];
	sMsg: any = "";

	ngOnInit() {}

	getFileDetails(e: any) {
		
		for (var i = 0; i < e.target.files.length; i++) {
			this.myFiles.push(e.target.files[i]);
		}
	}
	getFileDetailss(e: any) {
		
		for (var i = 0; i < e.target.files.length; i++) {
			this.myFiless.push(e.target.files[i]);
		}
	}

	
}
