<div class="helpSupprt">
    <div class="pageTitle">
        Settings
    </div>
    <div class="row">
        <div class="col-md-5 ">
            <div class="helpSupprtLeft">
                <div class="helpColmn">
                    <div class="helpText">PERSONAL SETTINGS</div>                                 
                </div>
                <div class="userDetailsContainer">
                    <div class="profileImgBox">
                        <div class="profileImg">
                            <span class="addEditText">Add/Edit Profile  Image</span>
                        </div>
                        <div class="settingsCommonBtn">                            
                            <button>Change Password</button>                            
                        </div>
                    </div> 
                    <div class="userInfo">
                        <div class="helpText">USER INFORMATION</div>
                        <button class="editBtn">Edit</button>
                        <div class="row mt-2">
                            <div class="col-md-6 lh-lg">First Name</div>
                            <div class="col-md-6 lh-lg">Last Name</div>
                            <div class="col-md-12 lh-lg">Company</div>
                            <div class="col-md-12 lh-lg">Title</div>
                            <div class="col-md-6 lh-lg">Portfolio</div>
                            <div class="col-md-6 lh-lg">Property</div>
                            <div class="col-md-6 lh-lg">Email</div>
                            <div class="col-md-6 lh-lg">Phone</div>
                            <div class="col-md-12 lh-lg mt-5">
                                <span><strong>User Access :</strong> User Role</span>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
        <div class="col-md-7">
            <div class="helpSupprtRight">
                <div class="helpColmn">
                    <div class="helpText">COMPANY SETTINGS</div>                              
                </div>
            </div>  
            
            <div class="row p-2">
                <div class="col-md-6">
                    <div class="mb-5">
                        <div class="helpColmn">
                            <div class="helpText">Add/Edit/Remove</div>                              
                        </div>
                        <div class="settingsCommonBtn"><button>Add/Edit/Remove Property</button></div>
                    </div>
                    <div  class="mb-5">
                        <div class="helpColmn">
                            <div class="helpText">Bulk Uploads</div>                              
                        </div>
                        <div>
                            <ng-container>
                                <div class="d-flex justify-content-between pt-2">
                                    <input type="file" id="file" multiple 
                                    (change)="getFileDetails($event)">
                                    <div class="settingsCommonBtn">
                                        <label for="file" class="mt-0" >Browse</label>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <!-- <pre>{{myFiles | json}}</pre> -->
                        <div class="mt-3" *ngFor="let file of myFiles">
                          <div>{{file.name}}</div> 
                          <!-- <div>{{file.name}} - {{file.type}} - {{file.size}}</div>  -->
                        </div>                        
                    </div>

                    <div class="mb-5">
                        <div class="helpColmn">
                            <div class="helpText">SHC Template Download (for Bulk Uploads)</div>                              
                        </div>                        
                        <!-- <pre>{{myFiles | json}}</pre> -->
                        <div style="color:#C5202F;">new_property_bulk-upload.csv</div>                          
                        <div style="color:#C5202F;">bulk_user_upload.csv</div>                          
                        <div style="color:#C5202F;">bulk_property_historical.csv</div>                          
                                           
                    </div>                    
                </div>
                <div class="col-md-6">                 
                    <div  class="mb-5">
                        <div class="helpColmn">
                            <div class="helpText">Company Logo</div>                              
                        </div>
                  
                        <div>
                            <ng-container>
                                <div class="d-flex justify-content-between pt-2">
                                    <input type="file" id="filees" multiple 
                                    (change)="getFileDetailss($event)">
                                    <div class="settingsCommonBtn">
                                        <label for="filees" class="mt-0" >Browse</label>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <!-- <pre>{{myFiles | json}}</pre> -->
                        <div class="mt-3" *ngFor="let filee of myFiless">
                          <div>{{filee.name}}</div> 
                          <!-- <div>{{file.name}} - {{file.type}} - {{file.size}}</div>  -->
                        </div> 
                    </div>

                    <div  class="mb-5">
                        <div class="helpColmn">
                            <div class="helpText">Company Brand Colors</div>                              
                        </div>                  
                                                  
                        <div class="d-flex justify-content-between pt-2"> 
                            <div class="d-flex align-items-center">
                                <label  class="colorPlateInput mb-0" for="favcolor">Type Hex Color Code Here </label>&nbsp;&nbsp;&nbsp;
                                <input type="color" id="favcolor" name="favcolor" value="#C5202F">
                            </div>                              
                            
                            <div class="settingsCommonBtn">
                                <label class="mt-0" >Submit</label>
                            </div>                                
                        </div> 

                        <div class="d-flex justify-content-between pt-2"> 
                            <div class="d-flex align-items-center">
                                <label  class="colorPlateInput mb-0" for="favcolor">Type Hex Color Code Here </label>&nbsp;&nbsp;&nbsp;
                                <input type="color" id="favcolor" name="favcolor" value="#000">
                            </div>                              
                            
                            <div class="settingsCommonBtn">
                                <label class="mt-0" >Submit</label>
                            </div>                                
                        </div>                            
                                             
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>







