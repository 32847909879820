import { Component, Input, OnInit } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-leftnav',
  templateUrl: './leftnav.component.html',
  styleUrls: ['./leftnav.component.scss'],
})
export class LeftnavComponent implements OnInit {
  @Input()
  inputFromParent: any;
  list = [
    {
      number: '2',
      name: 'Portfolio',
      icon: 'icon icon-portfolio',
      routerLink: '/leasing/portfolio',
      tooltip: 'Portfolio',
    },
    {
      number: '3',
      name: 'Properties',
      icon: 'icon icon-properties',
      routerLink: '/leasing/property',
      tooltip: 'Properties',
    },
    {
      number: '4',
      name: 'Lease',
      icon: 'icon icon-lease',
      routerLink: '/leasing/lease',
      tooltip: 'Lease',
    },
  ];
  list1 = [
    {
      name: 'Report',
      icon: 'icon icon-report',
      routerLink: '/leasing/report',
      tooltip: 'Report',
    },
    {
      name: 'Help',
      icon: 'icon icon-help',
      routerLink: null,
      tooltip: 'Help',
    },
    {
      number: '4',
      name: 'Settings',
      icon: 'icon icon-settings',
      routerLink: 'null',
      tooltip: 'Settings',
    },
  ];

  markeNavList = [
    {
      name: 'Markets',
      icon: 'icon icon-marketplace',
      routerLink: '/market-overview/markets',
      tooltip: 'Markets',
    },
    {
      name: 'Compsets',
      icon: 'icon icon-compset',
      routerLink: '/market-overview/compset',
      tooltip: 'Compsets',
    },
    {
      name: 'Properties',
      icon: 'icon icon-properties',
      routerLink: '/market-overview/market-overview-property',
      tooltip: 'Properties',
    },
  ];

  selectedValue: any = '1';

  constructor(public router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('market')) {
          this.selectedValue = '2'
        } else {
          this.selectedValue = '1'
        }
      }
    });
    if (this.router.url.includes('market')) {
      this.selectedValue = '2'
    }
  }

}

