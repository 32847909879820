import { Component, inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  private auth = inject(AuthService)

  slides = [
    {
      image: 'assets/img/slide-1.png',
      logo: 'assets/img/logo/Logo_full.svg',
      text: 'Purpose built for the Student Housing Industry - by the Student Housing Industry.',
      heading: 'All of your leasing data in one location.',
      button: 'Login',
    },
    {
      image: 'assets/img/slider2.png',
      logo: 'assets/img/logo/Logo_full.svg',
      text: 'With SH//centrals centralized system, you and your team can access all of your must-know metrics right when you need them most.',
      heading: 'Up to date Data and Metrics',
      button: 'Login',
    },
    {
      image: 'assets/img/slide-3.png',
      logo: 'assets/img/logo/Logo_full.svg',
      text: 'Fully managed setup and integration to quickly give access to data & metrics. Reports are ready for Management, Lender, Investor or Team review via email and directly on your dashboard.',
      heading: 'Quick & Easy Set-Up',
      button: 'Login',
    },
  ];

  login() {
    this.auth.loginWithRedirect({ appState: { target: '/leasing' } });
  }
}
