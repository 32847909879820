import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { customAuthGuardGuard } from './shared/custom-auth-guard.guard';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { LoginComponent } from './components/login/login.component';
import { HelpSupportComponent } from './components/help-support/help-support.component';
import { SettingsComponent } from './components/settings/settings.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
  },
  {
    path: 'help-support',
    component: HelpSupportComponent,
  },
  {
    path: 'settings',
    component: SettingsComponent,
  },
  {
    path: 'leasing',
    loadChildren: () => import('./leasing/leasing.module').then((m) => m.LeasingModule),
    canActivate: [AuthGuard, customAuthGuardGuard],
  },
  {
    path: 'market-overview',
    loadChildren: () =>
      import('./market-overview/market-overview.module').then((m) => m.MarketOverviewModule),
    canActivate: [AuthGuard, customAuthGuardGuard],
  },
  // {
  //   path: 'portfolio',
  //   loadChildren: () =>
  //     import('./portfolio/portfolio.module').then((m) => m.PortfolioModule),
  //   canActivate: [AuthGuard, customAuthGuardGuard],
  // },
  // {
  //   path: 'property',
  //   loadChildren: () =>
  //     import('./property/property.module').then((m) => m.PropertyModule),
  //   canActivate: [AuthGuard, customAuthGuardGuard],
  // },
  // {
  //   path: 'lease',
  //   loadChildren: () =>
  //     import('./lease/lease.module').then((m) => m.LeaseModule),
  //   canActivate: [AuthGuard, customAuthGuardGuard],
  // },
  // {
  //   path: 'report',
  //   loadChildren: () =>
  //     import('./report/report.module').then((m) => m.ReportModule),
  //   canActivate: [AuthGuard, customAuthGuardGuard],
  // },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
