import { Injectable } from '@angular/core';
// import jwtDecode, * as jwt_decode from 'jwt-decode';
// import jwtDecode, * as JWT from 'jwt-decode';
// import jwtDecode, * as JWT from 'jwt-decode';
import { default as decode } from 'jwt-decode';
import { JwtHelperService } from '@auth0/angular-jwt';
@Injectable({
  providedIn: 'root'
})
export class JwtServiceService {

  constructor() { }
  DecodeToken(token: string): string {
    const helper = new JwtHelperService();

    const decodedToken = helper.decodeToken(token);

    // Other functions
    return decodedToken;
  }
}
