import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";

import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { JwtServiceService } from "../services/jwt-service.service";
import { environment } from "src/environments/environment";



@Injectable()
export class customAuthGuardGuard {

  constructor(
    private auth: AuthService,
    private router: Router,
    private jwtService: JwtServiceService
  ) { }

  canActivate(
    
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const token = this.auth.getAccessTokenSilently().subscribe((result: any) => {
      let decodeToken: any = this.jwtService.DecodeToken(result)
      let permissions = decodeToken.permissions;
      let env: string = '';
      permissions.forEach((element: any) => {
        if (element.includes('env')) {
          env = element.split(':')[1];
        }
      });
      if (env == environment.env) {
        return true
      } else {
        this.router.navigate(["access-denied"]);
        return false
      }
    });
    return true;
  }
}

