import { Component, inject, ViewChild } from "@angular/core";
import { ColDef, GridApi, GridReadyEvent, GridOptions } from 'ag-grid-community'
import { LeaseService } from "@app/services/lease.service";
import { SharedService } from "@app/services/shared.service";
import { Subscription } from "rxjs";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AgGridComponent } from "../ag-grid/ag-grid.component";
@Component({
	selector: "app-settings",
	templateUrl: "./settings.component.html",
	styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent {
	subscription: Subscription = new Subscription();
	showEditPopup: boolean = false;
	myFiles: any[] = [];
	myFiless: any[] = [];
	sMsg: any = "";
	rowData: any = [];
	myForm: FormGroup;
	role: any[];
	portfolio: any[];
	params: any;
	private sharedService = inject(SharedService);
	constructor(private fb: FormBuilder) { }
	@ViewChild("aggrid") child!: any;

	private leasingservice = inject(LeaseService);

	ngOnInit() {
		this.subscription = this.leasingservice.getusers().subscribe((res: any) => {
			this.rowData = res
		});
		this.subscription = this.leasingservice.getroles().subscribe((res: any) => {
			this.role = res;
		});
		this.subscription = this.leasingservice.getportfolio().subscribe((res: any) => {
			this.portfolio = res
			console.log(res);
		})
		this.myForm = this.fb.group({
			name: ['', Validators.required],
			lastname: ['',],
			email: ['', [Validators.required, Validators.email]],
			// password: ['', [Validators.required, Validators.minLength(6)]],
			phone: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]], // Indian phone pattern
			company: ['',],
			title: ['',],
			portfolio: ['', Validators.required], // First dropdown for country
			property: ['', Validators.required], // Second dropdown for provider
			roletype: ['', Validators.required],
		});
	}

	getFileDetails(e: any) {
		for (var i = 0; i < e.target.files.length; i++) {
			this.myFiles.push(e.target.files[i]);
		}
	}

	public defaultColDef: ColDef = {
		sortable: true,
		filter: true,
	};

	private gridApi!: GridApi;
	//   subscription!: Subscription;
	dataChange: any = '';

	columnDefs: ColDef[] = [
		{ field: 'FirstName', headerName: "User First Name", width: 150, minWidth: 90, maxWidth: 150, headerClass: 'agGridHeadStyle', cellClass: "text-center" },
		{ field: 'LastName', headerName: "User Last Name", width: 150, minWidth: 90, maxWidth: 150, headerClass: 'agGridHeadStyle', cellClass: "text-center" },
		{
			headerName: "Portfolio",
			valueGetter: this.getPortfolios,
			width: 400,
			minWidth: 100,
			maxWidth: 400,
			wrapText: true,
			autoHeight: true,
			headerClass: 'agGridHeadStyle',
			cellClass: "text-center"
		},
		{ headerName: "Property", valueGetter: this.getProperty, width: 290, minWidth: 70, maxWidth: 300, headerClass: 'agGridHeadStyle', cellClass: "text-center" },
		{ field: 'Title', headerName: "Title", width: 200, minWidth: 70, maxWidth: 300, headerClass: 'agGridHeadStyle', cellClass: "text-center" },
		{ field: 'Email', headerName: "Email Address", width: 200, minWidth: 90, maxWidth: 300, headerClass: 'agGridHeadStyle', cellClass: "text-center" },
		{ field: 'Role', headerName: "User Role", width: 200, minWidth: 80, maxWidth: 300, headerClass: 'agGridHeadStyle text-wrap', cellClass: "text-center" },
		// { field: 'Studios', headerName: "Edit", width: 200, minWidth: 70, maxWidth: 300, headerClass: 'agGridHeadStyle', cellClass: "text-center" },
		{
			headerName: 'Actions', headerClass: 'agGridHeadStyle text-wrap', cellClass: "text-center",
			cellRenderer: (params: any) => { return `<span class="edit-btn">Edit</span>`; },
		}
	];

	getPortfolios(params: any): string {
		// console.log(params.data.Portfolios.map((portfolio: any) => portfolio.Name).join("\n"))
		return params.data.Portfolios.map((portfolio: any) => portfolio.Name).join(",");
	}
	getProperty(params: any): string {
		// console.log(params.data.Role)
		if (params.data.Role == "ShCentralAdmin") {
			return "";
		}
		else {
			const properties = params.data.Portfolios.flatMap((portfolio: any) => portfolio.Properties.map((property: any) => property.Name));
			return properties.join(', ');
		}
	}
	// rowData = [{
	// 	Market_Name: 'John',
	// 	City: 'Doe',
	// 	State: 'Portfolio A',
	// 	Total_Beds: 'Property 1',
	// 	No_Of_Units: 'Manager',
	// 	Occupancy: 'john.doe@example.com',
	// 	Prelease: 'Admin',
	// 	Studios: '',
	// },
	// {
	// 	Market_Name: 'Jane',
	// 	City: 'Smith',
	// 	State: 'Portfolio B',
	// 	Total_Beds: 'Property 2',
	// 	No_Of_Units: 'Director',
	// 	Occupancy: 'jane.smith@example.com',
	// 	Prelease: 'User',
	// 	Studios: '',
	// },];

	editUser(data: any) {
		console.log("hello");
		alert("haa haa")

		// const selectedUser = this.rowData.
		// 		find(user =>user.id === selectedUserId); this.selectedUser = { ...selectedUser };
		this.showEditPopup = true;
	}
	// createuser() {
	// 	this.showEditPopup = !this.showEditPopup
	// 	this.leasingservice.createusers({
	// 		Email: "sihemid803@skrak.com",
	// 		FirstName: "Anjali12",
	// 		FamilyName: "Sharma21",
	// 		Operator_Id: "4e22e3c7-df6f-11ed-bf25-da140f43dc16",
	// 		Title: "admin"
	// 	}).subscribe((res: any) => {
	// 		console.log(res);
	// 	})

	// }

	onGridReady
		(params: any) {
		params.api.
			addEventListener('cellClicked', (event: any) => {
				if (event.column.colId === 'Actions' && event.event.target.classList.contains(
					'edit-btn'
				)) {
					this.editUser(event.data);
				}
			});
		this.gridApi = params.api;
		this.params = params
	}
	onSubmit(): void {
		if (this.myForm.valid) {
			console.log('Form Submitted', this.myForm.value);
			// Close the modal manually here if needed
			const modalCloseButton = document.querySelector('.btn-close') as HTMLElement;
			modalCloseButton.click();
			this.leasingservice.createusers(this.myForm.value).subscribe((res: any) => {
				console.log(res);
			})
		} else {
			console.log('Form is invalid');
		}
	}
	gridOptions: GridOptions = {
		columnDefs: this.columnDefs,
		rowData: this.rowData
	};

	onExportClick() {
		// this.gridApi.exportDataAsExcel();
		this.gridApi.exportDataAsCsv(this.params);
	}
}
