<div class="left-nav-container">
  <div class="left-nav-top">
    <div id="leaseNav" *ngIf="selectedValue !== '2'">
      <a tooltip="{{ item.tooltip }}" placement="right" container="body" *ngFor="let item of list"
        routerLink="{{ item.routerLink }}" routerLinkActive="active-link">
        <i class="{{ item.icon }}" aria-hidden="false"></i>
      </a>
    </div>
    <div id="marketsNav" *ngIf="selectedValue === '2'">
      <a tooltip="{{ item.tooltip }}" placement="right" container="body" *ngFor="let item of markeNavList"
        routerLink="{{ item.routerLink }}" routerLinkActive="active-link">
        <i class="{{ item.icon }}" aria-hidden="false"></i>
      </a>
    </div>
  </div>

  <div class="left-nav-bottom">
    <div class="hr-line"></div>
    <a *ngIf="selectedValue !== '2'" tooltip="Report" placement="right" container="body" routerLink="leasing/report"
      routerLinkActive="active-link">
      <i class="icon icon-report" aria-hidden="false"></i>
    </a>
    <a tooltip="Help" placement="right" container="body" routerLink="/help-support" routerLinkActive="active-link">
      <i class="icon icon-help" aria-hidden="false"></i>
    </a>
    <a tooltip="Settings" placement="right" container="body" routerLink="/settings" routerLinkActive="active-link">
      <i class="icon icon-settings" aria-hidden="false"></i>
    </a>
    <!-- <a tooltip="Settings" placement="right" container="body" routerLink="/settings" routerLinkActive="active-link">
      <i class="icon icon-settings" aria-hidden="false"></i>
    </a> -->
  </div>
</div>