import { Component, OnInit, inject } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { Router } from "@angular/router";
import { SharedService } from "@app/services/shared.service";
import { JwtServiceService } from "@app/services/jwt-service.service";
import { LeasingApiService } from "@app/services/leasing-api.service";
import { DatePipe } from "@angular/common";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
	selectedValue = "1";

	private router = inject(Router);
	public auth = inject(AuthService);

	constructor(
		private sharedService: SharedService,
		private jwtService: JwtServiceService,
		private datePipe: DatePipe,
		private leaseService: LeasingApiService,
	) { }

	onChange(event: any) {
		let stateData = this.sharedService.getMarketOverviewDropdownStateValue();
		// stateData.seleteType = event;
		stateData.marketOrleasing = event;
		this.sharedService.updateMarketOverviewDropdownState(stateData);
		if (event === "1") {
			this.router.navigate(["leasing"]);
		} else if (event === "2") {

			this.router.navigate(["market-overview"]);
		}
	}

	ngOnInit(): void {
		if (this.router.url.includes("market")) {
			this.selectedValue = "2";
		}


	}

	logout() {
		this.auth.logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		});
	}

	redirect() {
		if (this.selectedValue === "1") {
			this.router.navigate(["leasing"]);
		} else {
			this.router.navigate(["market-overview"]);
		}
	}
}
