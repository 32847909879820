<header class="header">
  <nav>
    <div class="logo"><img src="assets/img/monogramdashboard.svg" (click)="redirect()" class="icon-hamburger"
        style="cursor: pointer" alt="" aria-hidden="true" /></div>
    <div class="dataSelection">
      <div class="content-wrapper mr-auto">
        <select class="ms-3 p-1 form-select bg-danger text-white" [(ngModel)]="selectedValue"
          (ngModelChange)="onChange($event)">
          <option value="1" selected>Leasing</option>
          <option value="2">Markets</option>
          <option value="3" disabled>Marketing (Coming soon)</option>
        </select>
      </div>
    </div>
    <div class="content-wrapper">
      <div class="dropdown user-image-wrapper" *ngIf="auth.user$ | async as user">
        <img src="{{ user.picture }}" alt="" class="dropdown-toggle user-image" role="button" id="dropdownMenuLink"
          data-bs-toggle="dropdown" aria-expanded="false" />
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <li>
            <span class="dropdown-item disabled">{{ user.name }}</span>
          </li>
          <li>
            <button class="dropdown-item" (click)="logout()">Logout</button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>