import { environment } from './../environments/environment';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule, AuthHttpInterceptor } from '@auth0/auth0-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { LeftnavComponent } from './components/leftnav/leftnav.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToastrModule } from 'ngx-toastr';
import { customAuthGuardGuard } from './shared/custom-auth-guard.guard';
import { JwtServiceService } from './services/jwt-service.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { LoaderInterceptor } from './shared/loader.interceptor';
import { LoginComponent } from './components/login/login.component';
import { HelpSupportComponent } from './components/help-support/help-support.component';
import { SettingsComponent } from './components/settings/settings.component';
import { DatePipe } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';


@NgModule({
  declarations: [AppComponent, HeaderComponent, LeftnavComponent, LoginComponent, HelpSupportComponent, SettingsComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CarouselModule,
    ButtonsModule,
    FormsModule,
    AuthModule.forRoot({
      domain: environment.auth0.domain,
      clientId: environment.auth0.clientId,
      cacheLocation: 'localstorage',
      authorizationParams: {
        audience: environment.auth0.audience,
        redirect_uri: `${window.location.origin}/leasing`,
        scope: environment.auth0.scopes.readuser,
      },
      httpInterceptor: {
        allowedList: [
          {
            uri: `${environment.apiEndpoint}/*`,
            tokenOptions: {
              authorizationParams: {
                audience: environment.auth0.audience,
              },
            },
          },
        ],
      },
    }),
    BrowserAnimationsModule,
    BsDatepickerModule,
    BsDropdownModule.forRoot(),
    ReactiveFormsModule,
    NgSelectModule,
    AgGridModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxSpinnerModule,
    TooltipModule.forRoot(),
    ToastrModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    customAuthGuardGuard,
    JwtServiceService,
    BsModalService,
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
