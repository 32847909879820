<div class="helpSupprt">
    <div class="pageTitle">
        Settings
    </div>
    <div class="row">
        <div class="col-md-5 ">
            <div class="helpSupprtLeft">
                <div class="helpColmn">
                    <div class="helpText">PERSONAL SETTINGS</div>
                </div>
                <div class="userDetailsContainer">
                    <div class="profileImgBox">
                        <div class="profileImg">
                            <span class="addEditText">Add/Edit Profile Image</span>
                        </div>
                        <div class="settingsCommonBtn">
                            <button>Change Password</button>
                        </div>
                    </div>
                    <div class="userInfo">
                        <div class="helpText">USER INFORMATION</div>
                        <button class="editBtn">Edit</button>
                        <div class="row mt-2">
                            <div class="col-md-6 lh-lg">First Name</div>
                            <div class="col-md-6 lh-lg">Last Name</div>
                            <div class="col-md-6 lh-lg">Email</div>
                            <div class="col-md-6 lh-lg">Phone</div>
                            <div class="col-md-6 lh-lg">Company</div>
                            <div class="col-md-6 lh-lg">Title</div>
                            <div class="col-md-12 lh-lg">
                                <span><strong>User Access :</strong> User Role</span>
                            </div>
                            <div class="col-md-6 lh-lg">
                                <!-- Portfolio -->
                                <div class="justify-content-center mr-1 " style=" ">
                                    <select class="form-select text-white weekdays-subheader"
                                        style="padding-right: 2rem">
                                        <option>Portfolio</option>
                                    </select>
                                </div>

                            </div>
                            <div class="col-md-6 lh-lg">
                                <!-- Property -->
                                <div class="justify-content-center mr-1 " style=" ">
                                    <select class="form-select text-white weekdays-subheader"
                                        style="padding-right: 2rem">
                                        <option>Property</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <div class="col-md-7">
            <!-- <div class="helpSupprtRight">
                <div class="helpColmn">
                    <div class="helpText">COMPANY SETTINGS</div>                              
                </div>
            </div>  
            
            <div class="row p-2">
                <div class="col-md-6">
                    <div class="mb-5">
                        <div class="helpColmn">
                            <div class="helpText">Add/Edit/Remove</div>                              
                        </div>
                        <div class="settingsCommonBtn"><button>Add/Edit/Remove Property</button></div>
                    </div>
                    <div  class="mb-5">
                        <div class="helpColmn">
                            <div class="helpText">Bulk Uploads</div>                              
                        </div>
                        <div>
                            <ng-container>
                                <div class="d-flex justify-content-between pt-2">
                                    <input type="file" id="file" multiple 
                                    (change)="getFileDetails($event)">
                                    <div class="settingsCommonBtn">
                                        <label for="file" class="mt-0" >Browse</label>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                       
                        <div class="mt-3" *ngFor="let file of myFiles">
                          <div>{{file.name}}</div> 
                        
                        </div>                        
                    </div>

                    <div class="mb-5">
                        <div class="helpColmn">
                            <div class="helpText">SHC Template Download (for Bulk Uploads)</div>                              
                        </div>                        
                    
                        <div style="color:#C5202F;">new_property_bulk-upload.csv</div>                          
                        <div style="color:#C5202F;">bulk_user_upload.csv</div>                          
                        <div style="color:#C5202F;">bulk_property_historical.csv</div>                          
                                           
                    </div>                    
                </div>
                <div class="col-md-6">                 
                    <div  class="mb-5">
                        <div class="helpColmn">
                            <div class="helpText">Company Logo</div>                              
                        </div>
                  
                        <div>
                            <ng-container>
                                <div class="d-flex justify-content-between pt-2">
                                    <input type="file" id="filees" multiple 
                                    (change)="getFileDetailss($event)">
                                    <div class="settingsCommonBtn">
                                        <label for="filees" class="mt-0" >Browse</label>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                      
                        <div class="mt-3" *ngFor="let filee of myFiless">
                          <div>{{filee.name}}</div> 
                          
                        </div> 
                    </div>

                    <div  class="mb-5">
                        <div class="helpColmn">
                            <div class="helpText">Company Brand Colors</div>                              
                        </div>                  
                                                  
                        <div class="d-flex justify-content-between pt-2"> 
                            <div class="d-flex align-items-center">
                                <label  class="colorPlateInput mb-0" for="favcolor">Type Hex Color Code Here </label>&nbsp;&nbsp;&nbsp;
                                <input type="color" id="favcolor" name="favcolor" value="#C5202F">
                            </div>                              
                            
                            <div class="settingsCommonBtn">
                                <label class="mt-0" >Submit</label>
                            </div>                                
                        </div> 

                        <div class="d-flex justify-content-between pt-2"> 
                            <div class="d-flex align-items-center">
                                <label  class="colorPlateInput mb-0" for="favcolor">Type Hex Color Code Here </label>&nbsp;&nbsp;&nbsp;
                                <input type="color" id="favcolor" name="favcolor" value="#000">
                            </div>                              
                            
                            <div class="settingsCommonBtn">
                                <label class="mt-0" >Submit</label>
                            </div>                                
                        </div>                            
                                             
                    </div>
                </div>
            </div> -->

        </div>
    </div>
    <div class="agGridTableContainer">
        <div class="searchTableData">
            <span class="searchInputField">
                <label>Name</label>
                <span class="d-flex position-relative">
                    <i class="bi bi-search"></i>
                    <input type="text" id="filter-text-box" placeholder="Search" />
                </span>
            </span>

            <div class="d-flex align-items-center justify-content-between ml-4 ml-sm-2 text-white">
                <button (click)="onExportClick()" class="nav-link nav-icon btn-list btn btn-success mr-4"><img
                        style="height: 20px" src="assets/img/excel.svg" alt="" /></button>
                <button type="button" class="btn createNewProp-btn" data-bs-toggle="modal" data-bs-target="#myModal">
                    Add User
                </button>
            </div>
        </div>
        <ag-grid-angular style="width: 100%; height:400px" class="ag-theme-alpine agGridTable" [rowData]="rowData"
            [columnDefs]="columnDefs" [cacheQuickFilter]="true" (gridReady)="onGridReady($event)"
            [gridOptions]="gridOptions" #aggrid>
        </ag-grid-angular>
    </div>
</div>
<!-- <ng-container *ngIf="showEditPopup">

    <div class="edit-popup">
        <h3>Edit User</h3>
        <form ( ngSubmit )="onSave()">
            <label>First Name:</label>
            <input [( ngModel )]="selectedUser.firstName" name="firstName" required>
            <label>Last Name:</label>
            <input [( ngModel )]="selectedUser.lastName" name="lastName" required>
            <label>Email:</label>
            <input [( ngModel )]="selectedUser.email" name="email" required type="email">
            <div class="popup-actions">
                <button type="submit">Save</button>
                <button type="button" ( click )="onClose()">Close</button>
            </div>
        </form>
    </div>
</ng-container> -->

<div class="modal fade" id="myModal" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered modal-xl
    ">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myModalLabel">Modal with Form</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <!-- Form Inside Modal -->
                <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
                    <div class="row mt-2">
                        <div class="mb-3 col-md-12">
                            <div class="row">
                                <div class="mb-3 col-md-6">
                                    <label for="name" class="form-label">First Name</label>
                                    <input type="text" id="name" formControlName="name" class="form-control"
                                        [ngClass]="{ 'is-invalid': myForm.get('name')?.invalid && myForm.get('name')?.touched }"
                                        placeholder="Enter your name" />
                                    <div *ngIf="myForm.get('name')?.invalid && myForm.get('name')?.touched"
                                        class="invalid-feedback">
                                        Name is required.
                                    </div>
                                </div>
                                <div class="mb-3 col-md-6">
                                    <label for="name" class="form-label">Last Name</label>
                                    <input type="text" id="name" formControlName="lastname" class="form-control"
                                        [ngClass]="{ 'is-invalid': myForm.get('lastname')?.invalid && myForm.get('lastname')?.touched }"
                                        placeholder="Enter your name" />
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="email" class="form-label">Email</label>
                                    <input type="email" id="email" formControlName="email" class="form-control"
                                        [ngClass]="{ 'is-invalid': myForm.get('email')?.invalid && myForm.get('email')?.touched }"
                                        placeholder="Enter your email" />
                                    <div *ngIf="myForm.get('email')?.invalid && myForm.get('email')?.touched"
                                        class="invalid-feedback">
                                        Please enter a valid email.
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="phone" class="form-label">Phone Number</label>
                                    <input type="tel" id="phone" formControlName="phone" class="form-control"
                                        [ngClass]="{ 'is-invalid': myForm.get('phone')?.invalid && myForm.get('phone')?.touched }"
                                        placeholder="Enter your phone number" />
                                    <div *ngIf="myForm.get('phone')?.invalid && myForm.get('phone')?.touched"
                                        class="invalid-feedback">
                                        Please enter a valid phone number.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 col-md-12">
                            <div class="row">
                                <div class="mb-3 col-md-6">
                                    <label for="name" class="form-label">Company</label>
                                    <input type="text" id="name" formControlName="company" class="form-control"
                                        [ngClass]="{ 'is-invalid': myForm.get('company')?.invalid && myForm.get('company')?.touched }"
                                        placeholder="Enter your company name" />
                                </div>
                                <div class="mb-3 col-md-6">
                                    <label for="name" class="form-label">Title</label>
                                    <input type="text" id="name" formControlName="title" class="form-control"
                                        [ngClass]="{ 'is-invalid': myForm.get('title')?.invalid && myForm.get('title')?.touched }"
                                        placeholder="Enter Title" />
                                </div>
                                <!-- <label for="password" class="form-label">Password</label>
                            <input type="password" id="password" formControlName="password" class="form-control"
                                [ngClass]="{ 'is-invalid': myForm.get('password')?.invalid && myForm.get('password')?.touched }"
                                placeholder="Enter your password" />
                            <div *ngIf="myForm.get('password')?.invalid && myForm.get('password')?.touched"
                                class="invalid-feedback">
                                Password must be at least 6 characters long.
                            </div> -->
                            </div>
                        </div>
                        <div class="mb-3 col-md-12">
                            <div class="row">
                                <div class="mb-3 col-md-4">
                                    <label for="portfolio" class="form-label">Portfolio</label><br>
                                    <select id="portfolio" formControlName="portfolio" class="form-control">
                                        <option value="">portfolio</option>
                                        <option>portfolio </option>
                                    </select>
                                    <!-- <div *ngIf="country?.invalid && country?.touched">
                                      <small>Country is required.</small>
                                    </div> -->
                                </div>
                                <div class="mb-3 col-md-4">
                                    <label for="property" class="form-label">Property</label><br>
                                    <select id="property" formControlName="property" class="form-control">
                                        <option value="">Select a property</option>
                                        <option [value]="">property</option>
                                    </select>
                                    <!-- <div *ngIf="provider?.invalid && provider?.touched">
                                      <small>Provider is required.</small>
                                    </div> -->
                                </div>
                                <div class="mb-3 col-md-4">
                                    <label for="roletype" class="form-label">Role</label><br>
                                    <select id="roletype" formControlName="roletype" class="form-control">
                                        <option *ngFor="let rolename of role" [ngValue]="rolename.role_name">
                                            {{rolename.role_name}}
                                        </option>
                                    </select>
                                    <!-- <div *ngIf="provider?.invalid && provider?.touched">
                                      <small>Provider is required.</small>
                                    </div> -->
                                </div>
                            </div>
                        </div>

                    </div>
                    <button type="submit" class="btn btn-primary" [disabled]="myForm.invalid">Submit</button>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>